






























import Vue from "vue";
import axios from "axios";
export default Vue.extend({
  data: () => ({
    honors: [] as any,
    page: 1,
    totalPage: [] as any,
    numInOnePage: 10,
  }),
  mounted: function () {
    console.log("honor list mounted");
    axios
      .post(Vue.prototype.serverAddr + "/api/get-item-num", {
        collection: "achievement",
        need:{
            type:1
        } 
      })
      .then((response) => {
        console.log("get response");
        this.totalPage = Math.ceil(
          (response.data as any).num / this.numInOnePage
        );
      });
    this.getHonorList();
  },
  watch: {
    page: function (val, oldVal) {
      console.log("page" + oldVal + " to " + val);
      this.getHonorList();
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
  methods: {
    getHonorList() {
      axios
        .post(Vue.prototype.serverAddr + "/api/get-item-list", {
          collection: "achievement",
          need:{
            type:1
          },
          page: this.page,
          numInOnePage: this.numInOnePage,
        })
        .then((response) => {
          console.log("get response");
          this.honors = response.data;
        });
    },
  },
});
